$linear-purple: linear-gradient(to bottom, #6827f9 0%, #2f80ed 100%);
$linear-purple__trans: linear-gradient(
  to bottom,
  rgba(#6827f9, 0.5) 0%,
  rgba(#2f80ed, 0.5) 100%
);
$white: #fff;
$white-200: #646464;
$white-400: #848486;
$white-500: #bdbdbd;
$gray: #47484a;
$black: #090a0d;
$black-100: #191a1d;
$black-200: #141518;
$pink: #ff0e83;
$red: #ff5649;
$purple: #5457f7;
