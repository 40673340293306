.card {
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: 0.65rem;

  @media (min-width: 768px) {
    padding: 0.65rem;
  }

  &.TicketCard {
    background-color: $black-200;
  }
}

.MyEventCard,
.ManageEventCard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 1rem;

  .grid-layout {
    display: grid;
    width: 100%;
  }

  .event {
    &__title {
      text-align: left;
      width: 100%;
      font-size: 1.35rem;
    }

    &__schedule {
      color: $white-200;
      display: flex;
      justify-content: space-between;
    }
  }
}

.ManageEventCard {
  .button-outline {
    background-color: transparent;
    border: 1px solid $purple;

    &:hover {
      background-color: $purple;
      color: $white;
    }
  }
}

.overflowY {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 0 2rem;
}
