.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  input,
  textarea,
  select {
    padding: 1rem;
    font-size: 1.2rem;
    background-color: $black-200;
    border-radius: 0.65rem;
    border: none;
    width: 100%;
  }
}

.grid-form {
  display: grid;
  gap: 1rem;

  .flex-group {
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
    flex: 0 1 100%;
  }
}
