button,
.button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  padding: 0.85rem 1.5rem;
  font-size: 1rem;
  background-color: $purple;
  color: $white;
  border: 1px solid transparent;
  border-radius: 0.65rem;

  &:hover {
    background-color: #fff;
    color: $black;
    border-color: $purple;
    cursor: pointer;
  }
}
